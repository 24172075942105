<template>
  <div class="">
    <div class="w-full flex text-c16 font-c4 text-left items-center item cursor-pointer">
      <div class="w-1/6">
        {{ item.reference }}
      </div>

      <div class="w-1/5 ml-4 flex items-center">
        <nameLabel
            :first="item.estimate.client.surname"
            :second="item.estimate.client.name"
            height="32px"
            width="32px"
            size="12px"
        />
        <div class="ml-2">{{item.estimate.client.surname}} {{item.estimate.client.name}}</div>
      </div>

      <div class="w-1/6 flex items-center ml-4">
          <div class="text-c16">
            {{ new Date(item.created_at).toLocaleDateString() }}
          </div>
      </div>

      <div class="w-1/6 ml-4">
        {{ new Date(item.estimate.expireDate).toLocaleDateString() }}
      </div>

      <div class="w-1/6 ml-4">
        {{ item.estimate.agency.label }}
      </div>

      <div class="w-1/6 ml-4 pr-6">
        <bouton v-if="item.orderAssemblyStep.length === 0" label="En attente" height="32px" background="#EAF5F2" color="#2B9E7C" size="14px"/>

        <bouton v-if="item.orderAssemblyStep.length > 0 && item.orderAssemblyStep.length < 8" label="En cours" height="32px" background="#FFF6E6" color="#FBA705" size="14px"/>
        <bouton v-if="item.orderAssemblyStep.length >= 8" label="Effectué" height="32px" background="#EEEBFD" color="#5138EE" size="14px"/>
      </div>
    </div>
  </div>
</template>

<script>
import down from '../../../assets/icons/down.svg'
import nameLabel from '../../helper/add/nameLabel'
import bouton from '../../helper/add/button'

export default {
  name: "Index",
  components: {
    nameLabel,
    bouton
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        down
      },
    }
  },

  computed: {
    // amount: function () {
    //   let amount = (this.item.financialData.totalAmount + this.item.financialData.deliveryAmount)-(this.item.financialData.discountAmount + this.item.financialData.supportAmount)
    //   return amount.toLocaleString()
    // }
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}
</style>
