<template>
  <div class="p-10 pr-16 all">
    <div>
      <div
        class="flex items-center"
      >
        <div class="w-1/2 flex text-left text-c48 items-center font-c6">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            original
          />
          <div class="ml-4">
            Montages <span v-if="user !== null">({{ user.length }})</span>
          </div>
        </div>
      </div>

      <div class="mt-6">
        <global-view
          v-if="!reloadSession"
          @info="retourEtape"
          @user="retourUser"
        />
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import globalView from '../../component/cdevs/montage/globalData'
import filter from '../../assets/icons/filter.svg'

export default {
  name: "Index",

  components: {
    globalView,
  },

  data () {
    return {
      icons: {
        back,
        filter
      },
      activeClient: false,
      reloadSession: false,
      selectedItem: null,
      user: null,
    }
  },

  created () {
  },

  methods: {
    retourUser (answer) {
      this.user = answer
    },

    retourEtape (answer) {
      this.selectedItem = answer
      this.activeDetail = true
    },
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.all{
  min-height: 700px;
}
</style>
