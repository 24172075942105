<template>
  <div class="rounded-10 h-auto bg-white w-full pt-8 pb-8 pl-8">
    <div class="mt-4">
      <div class="all">
        <div class="w-full flex text-90 text-c18 font-c5 text-left mb-6">
          <div class="w-1/6">
            Nº DE RÉF.
          </div>
          <div class="w-1/5 ml-4">
            CLIENT
          </div>
          <div class="w-1/6 ml-4">
            EDITION
          </div>
          <div class="w-1/6 ml-4">
            LIVRAISON
          </div>
          <div class="w-1/6 ml-4 text-left">
            AGENCE
          </div>
          <div class="w-1/6 ml-4">
            MONTAGE
          </div>
        </div>

        <div class="diviser" />

        <div
          v-if="charge"
          class="flex justify-center mt-16"
        >
          <easy-spinner
            type="dots"
            size="70"
          />
        </div>

        <div v-if="tracker.length === 0 && !charge" class="mt-10 text-center text-60">
          Aucune commande disponible dans cette catégorie
        </div>

        <div
          v-for="(item, index) in tracker"
          :key="index"
          class="mt-5"
        >
          <div
            v-if="index > 0"
            class="diviser mt-5"
          />
          <div @click="selected(item)">
            <liste
              class="mt-5"
              :item="item"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import arrow from '../../../assets/icons/arrow.svg'
import agenda from '../../../assets/icons/agenda.svg'
import down from '../../../assets/icons/down.svg'
import liste from './liste'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'
// import commandeListe from './commandeListe.json'

export default {
  name: "Index",
  components: {
    liste
  },

  props: {
  },

  data () {
    return {
      icons: {
        arrow,
        agenda,
        down
      },
      devis: [],
      charge: true,
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    tracker: function () {
      let retour = this.devis
      return retour
    }
  },

  created () {
    this.allCommande()
  },

  methods: {
    allCommande () {
      this.charge = true
      http.get(apiroutes.baseURL + apiroutes.assemblyListe)
          .then(response => {
            console.log(response)
            this.devis = response
            this.charge = false
            this.$emit('user', response)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    selected (item) {
      this.$router.push({ path: '/detailsMontage', query: { x: item.id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.filtr{
  background: rgba(242, 245, 249, 0.5);
  border: 0.5px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  height: 36.39px;
}
.compFiltre{
  width: auto;
  min-width: 9.1%;
}
.diviser {
  height: 0.5px;
  background-color: #DDDDDD;
}
</style>
